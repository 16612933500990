// Page Properties
$fonts: Aeonik, Inter, 'montserrat-okta', Arial, Helvetica, sans-serif;
$default-border-radius: 3px;
$container-width: 400px;
$container-min-width: 300px;
$num-tabs: 5;

// Font Size
$font-size: 14px;
$font-size-small: 13px;
$font-size-header: 15px;
$font-size-footer: 12px;
$font-size-default: 15px;

// Font Weight
$font-weight-body: normal;
$font-weight-labels: 600;
$font-weight-header: 600;

// Theme Color
$primary-bg-color: #fff;
$secondary-bg-color: #f9f9f9;

// Text Color
$header-text-color: #5e5e5e;
$dark-text-color: #1d1d21;
$medium-text-color: #6e6e78;
$light-text-color: #6e6e78; // NOTE: light <> medium merged
$link-text-color: #0074b3;
$placeholder-text-color: #aaa;
$error-text-color: #e34843;

// Button Color
$icon-button-bg-color: #fff;
$button-bg-color: #fbfbfb;
$dark-button-bg-color: #929292;
$primary-button-bg-color: #007dc1;
$success-button-bg-color: #4cbf9c;
$negative-button-bg-color: #c35151;
$disabled-button-text-color: #aaa;
$disabled-primary-button-text-color: #fff;
$disabled-primary-button-bg-color: #63b1d7;
$disabled-primary-border-color: #3096c9;

// Divider Colors
$header-divider-color: #ddd;
$form-divider-color: #e8e8e8;

// Social Auth Button Colors
$other-icon-bg-color: #fff;
$other-label-bg-color: $button-bg-color;
$other-border-color: darken($button-bg-color, 22%);

// Default Custom Button Colors
$default-custom-button-background-color: #ff5f73;
$default-custom-button-border-color: #d6001a;

// IDP PIV Button Colors
$piv-button-background-color: #fff;
$piv-button-border-text-color: #1662dd;
$piv-action-button-background-color: #e5edfb;
$piv-action-button-border-text-color: #124a94;

// Button Size
$input-height: 40px;
$button-height: 50px;
$button-line-height: $input-height - 4px;
$button-padding: 0 15px;

// Input field
$input-bg-color: #fff;
$input-border-color: #8c8c96;
$input-border-color-hover: #1d1d21;
$input-border-color-focus: #0074b3;
$input-shadow-color-focus: #51cbee;
$input-icons-color: #a7a7a7;

// Modal
$modal-bg-color: #f9f9f9;

// Footer

// Other
